<template>
  <CompanyModify refs="create" :company-id="companyId"></CompanyModify>
</template>

<script>
import { mapGetters, mapState, mapActions } from "vuex"
import AdminExtension from '../AdminExtension'
import CompanyModify from './CompanyModify'

export default AdminExtension.extend({
    name: 'CompanyAdd',
    props: {
      company: {
        require: false,
        type: Object,
      }
    },
    components: {
      CompanyModify,
    },
    computed:{
      companyId(){
        if (this.company){
          return this.company.id
        }else {
          return this.id
        }
      }
    },
    data() {
        return {
          id: null,
        }
    },
})
</script>
